(function() {
  'use strict';

  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU_CONTAINER = $('.header-menu-container');
  window.$HEADER_MENU = $('.header-menu > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');


  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW
    .on('resize', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
    })
    .trigger('resize');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = function() {
    return WINDOW_WIDTH > DESKTOP_WIDE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return (WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH);
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });
  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');


  $BODY
    .on('yiiListViewUpdating', function() {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', function(e, listId) {
      switch (listId) {
        case 'news-list':
        case 'user-orders-list':
          var $elemToScroll = $('#'.concat(listId));

          if ($elemToScroll.length && ($elemToScroll.data('scrollAfterUpdate') !== false)) {
            var speed = $elemToScroll.data('scrollSpeed') || 150,
                offset = $elemToScroll.data('scrollOffset') || 0;

            $WINDOW.scrollTo($elemToScroll, {axis: 'y', duration: speed, offset: offset});
          }
          break;
      }

      $.mouseLoader(false);
    });


  $DOCUMENT
    .ready(function() {
      $DOCUMENT
        .trigger('headerMenuEvents')
        .trigger('setBodyTopPadding')
        .trigger('setSearchInputPlaceholder')
        .trigger('initSpinner')
        .trigger('loadLeftColumnData');

      $WINDOW.on('resize', function() {
        $DOCUMENT
          .trigger('headerMenuEvents')
          .trigger('setBodyTopPadding')
          .trigger('setSearchInputPlaceholder')
          .trigger('reloadRotatorImages')
          .trigger('reloadRotatorImages')
          .trigger('loadLeftColumnData');
      });


      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if (this.checked) {
            $BODY.addClass('menu-is-opened');
          }
          else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Set user account menu available
      // -------------------------------
      $('.account-link ul').removeClass('dn_i');


      // Main rotator
      // ------------
      $('#main-rotator').lightSlider({
        item: 1,
        slideMargin: 0,
        mode: 'fade',
        speed: 500,
        auto: true,
        adaptiveHeight: true,
        pauseOnHover: true,
        loop: true,
        pause: 8000,
        pager: false,
        gallery: false,
        onSliderLoad: function() { $DOCUMENT.trigger('reloadRotatorImages'); }
      });


      // Load product images
      // -------------------
      $('.product-section-block-container .list-view-item-image > a, .product-block-container .list-view-item-image > a, .info-block-container .list-view-item-image > a').unveil({
        offset: 50,
        throttle: 200
      });

      // Product gallery
      // ---------------
      $('.product-card .product-gallery').lightSlider({
        item: 1,
        slideMargin: 0,
        mode: 'fade',
        speed: 500,
        pauseOnHover: true,
        loop: true,
        pager: true,
        gallery: true,
        vThumbWidth: 0,
        thumbItem: 0,
        galleryMargin: 0,
        thumbMargin: 0,
        currentPagerPosition: 'left'
      });

      // Product list block price calc
      // -----------------------------
      $('.product-block-container .list-view-item').each(function() {
        var $item = $(this);

        if ($item.find('.product-amount-input').length) {
          var parent = '#'.concat($item.prop('id'), ' .product-view-item-price-container'),
              $showcase = $item.closest('[id^="showcase-"]');

          if ($showcase.length)
            parent = '#'.concat($showcase.prop('id'), ' ', parent);

          $(parent.concat(' .product-price [data-price]'))
            .calcPrice({
              parentBlockSelector: parent,
              amountSelector: '.product-amount-input',
              price: {
                elementSelector: '.product-price [data-price]',
                suffix: ''
              },
              priceOld: {
                elementSelector: '.product-price [data-price-old]',
                suffix: ''
              },
              numberFormatDecimals: 2
            })
            .trigger('change');
        }
      });
    })

    // Set body top padding
    // --------------------
    .on('setBodyTopPadding', function() {
      if (IS_DESKTOP_WIDTH()) {
        $HEADER.removeClass('fixed');
        $BODY.add($HEADER_MENU.parent()).css('padding-top', 0);
      }
      else {
        $HEADER.addClass('fixed');
        $BODY.add($HEADER_MENU.parent()).css('padding-top', $HEADER_MENU_CONTAINER.outerHeight());
      }
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if (IS_DESKTOP_WIDTH()) {
        if (!$HEADER_MENU.hasClass('is-desktop-events')) {
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style')

            .closest('.icn-menu-product').appendTo('.product-menu > ul');

          $('span.content-top-panel-placeholder').replaceWith($('.content-top-panel'));
        }
      }
      else {
        if (!$HEADER_MENU.hasClass('is-mobile-events')) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          var $productRootItem = $('.product-menu .icn-menu-product').insertAfter('.header-menu .icn-menu-index');
          if (!$productRootItem.hasClass('is-hovered') && ($HEADER_MENU.children('.icn-menu-index').hasClass('active') || !$HEADER_MENU.hasClass('with-active')))
            $productRootItem.addClass('is-hovered').children('ul').slideDown(200);

          $('.content-top-panel').after('<span class="content-top-panel-placeholder"></span>').appendTo($HEADER_MENU_CONTAINER);

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if (!($(e.target).closest('.header-menu > ul').length || $(e.target).closest('.header-menu-button-container').length)) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu > ul div > a, .header-menu > ul div > span, .header-menu > ul .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              if ($self.prop('tagName').toUpperCase() === 'A')
                location.href = $self.prop('href');
              else if (isWithDropdown) {
                if (isOnHover)
                  $parent.removeClass('is-hovered').children('ul').slideUp(200);
                else {
                  $parent.siblings().removeClass('is-hovered').children('ul').slideUp(200);
                  $parent.addClass('is-hovered').children('ul').slideDown(200);
                }
              }
            });
        }
      }
    })

    // City list popup
    .on('click.cityListPopup', '.header-city-link a', function(e) {
      e.preventDefault();

      let $container = $('#city-list-popup'),
          callback = () => {
            $.fancybox.open({type: 'inline', src: $container});
          };

      if (!$container.hasClass('loading')) {
        if (!$container.children('.form-block-container').length) {
          $container.addClass('loading');
          $.mouseLoader(true);

          $.ajax({
            type: 'POST',
            url: '/ajax/render_content/',
            data: {
              ajaxRender: 'cityList',
              cityListType: IS_LANDSCAPE_TABLET_WIDTH() ? (IS_MOBILE_WIDTH() ? 'mobile' : 'tablet') : 'desktop',
            },
            dataType: 'html'
          })
            .done(function(data) {
              $.mouseLoader(false);

              $container.html(data);
              callback();
            })
            .fail(function() {
              $.mouseLoader(false);
              alert('Во время загрузки данных возникла ошибка, попробуйте позже.');
            })
            .always(function() {
              $container.removeClass('loading');
            });
        }
        else
          callback();
      }
    })

    // Set search input placeholder
    // ----------------------------
    .on('setSearchInputPlaceholder', function() {
      var $input = $('.content-top-panel .search-input-container input');

      if (IS_PORTRAIT_MOBILE_WIDTH()) {
        if (!$input.hasClass('is-mobile-events'))
          $input.addClass('is-mobile-events').attr('placeholder', $input.data('placeholderMobile'));
      }
      else if ($input.hasClass('is-mobile-events'))
        $input.removeClass('is-mobile-events').attr('placeholder', $input.data('placeholder'));
    })

    // Reload rotator lazy images
    // --------------------------
    .on('reloadRotatorImages', function() {
      var $slides = $('.main-slide-image > div'),
          reload = false;

      if ($slides.length) {
        if (IS_WIDTH_LESS_PORTRAIT_TABLET()) {
          if (!$slides.hasClass('is-mobile-events')) {
            $slides.addClass('is-mobile-events');
            reload = true;
          }
        }
        else {
          if ($slides.hasClass('is-mobile-events') || !$slides.hasClass('initialized')) {
            $slides.addClass('initialized').removeClass('is-mobile-events');
            reload = true;
          }
        }

        if (reload)
          $slides.each(function() {
            var $slide = $(this),
                image = $slide.data(IS_WIDTH_LESS_PORTRAIT_TABLET() ? 'src' : 'srcBig');

            $('<img/>').attr('src', image).on('load', function() {
              $(this).remove();
              $slide.css('background-image', 'url(\''.concat(image, '\')')).addClass('loaded');
            });
          });
      }
    })

    // Init spinner
    // ------------
    .on('initSpinner basketListUpdated', function() {
      $.widget('ui.customSpinner', $.ui.spinner, {
        _enhance: function() {
          this.uiSpinner = this.element
            .attr('autocomplete', 'off')
            .wrap(this._uiSpinnerHtml())
            .parent().parent()

            // Add buttons
            .append(this._buttonHtml());
        },
        _uiSpinnerHtml: function() {
          return '<div>';
        },
        _buttonHtml: function() {
          return ''.concat(
            '<div class="ui-spinner-button ui-spinner-up"><div><img src="/i/sp.gif" /></div></div>',
            '<div class="ui-spinner-button ui-spinner-down"><div><img src="/i/sp.gif" /></div></div>'
          );
        }
      });

      $('.js-spinner-input')
        .each(function() {
          var $spinner = $(this),
              min = $spinner.data('min') || 1,
              max = $spinner.data('max') || null,
              step = $spinner.data('step') || 1;

          $spinner
            .val(function(index, value) {
              if (value < min)
                return min;

              return value;
            })
            .customSpinner({
              culture: 'en',
              min: min,
              max: max,
              page: 0,
              step: step,

              change: function(e, ui) {
                e.preventDefault();

                var _min = $(e.target).data('min') || 1;

                if ($(e.target).val() < _min)
                  $(e.target).val(_min).trigger('change');
              },
              spin: function(e, ui) {
                e.preventDefault();

                $(e.target).val(ui.value).trigger('change');
              }
            });
        })
        .off('cut copy paste')
        .on('cut copy paste', function(e) {
          e.preventDefault();
        });
    })

    // Load left column data
    // ---------------------
    .on('loadLeftColumnData', function() {
      if (IS_DESKTOP_WIDTH()) {
        $('.left-column-block [data-url]:not(.loading)').addClass('loading').each(function() {
          var $self = $(this);

          $.ajax({
            type: 'POST',
            url: $self.data('url'),
            dataType: 'html'
          })
            .done(function(data) {
              $self.removeAttr('data-url').removeClass('loading').html(data).addClass('loaded');
            });
        });
      }
    })

    // Info delivery letters click
    // ---------------------------
    .on('click.infoDeliveryLetters', '.info-delivery-letters a', function(e) {
      e.preventDefault();

      var $self = $(this);

      if (!$self.hasClass('active')) {
        var id = $self.data('id');

        $self.addClass('active').parent().siblings().children('a.active').removeClass('active');

        if (id === '*')
          $('.info-delivery-cities > div').slideDown(200);
        else
          $('.info-delivery-cities > div[data-id="'.concat(id, '"]')).slideDown(200).siblings().slideUp(200);
      }
    })

    // Show/hide password input value
    .on('click.passwordEyeButton', '.form .form-row-password .password-eye', function(e) {
      e.preventDefault();

      var $self = $(this);

      if ($self.hasClass('opened'))
        $(this).removeClass('opened').siblings('input').attr('type', 'password');
      else
        $(this).addClass('opened').siblings('input').attr('type', 'text');
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {axis: 'y', duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this).parent();

      if (!$lnk.hasClass('active')) {
        var $parent = $lnk.parents('.js-tabs'),
            $destination = !!$parent.data('tabsDest') ? $($parent.data('tabsDest')) : $parent.next();

        $lnk.addClass('active').siblings().removeClass('active');
        $destination.children().addClass('dn').eq($lnk.index()).removeClass('dn').find('.product-block-container .list-view-item-image > a').trigger('lookup.unveil');
      }
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    clickContent: function(current) {
      return current.type === 'image' ? 'close' : false;
    },
    mobile: {
      clickContent: function(current) {
        return current.type === 'image' ? 'close' : false;
      },
      clickSlide: 'close',
      dblclickContent: false,
      dblclickSlide: false
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if (current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH()) {
        current.$content.find('.js-autofocus-inp').trigger('focus');
      }
    }
  });

  $.fancyBoxConfirm = function(opts) {
    opts = $.extend(true, {
      title: 'Вы уверены?',
      message: '',
      buttons: {
        yes: {
          label: 'Да',
          cssClass: ''
        },
        no: {
          label: 'Нет',
          cssClass: ''
        }
      },
      callbacks: {
        yes: $.noop,
        no: $.noop
      }
    }, opts || {});

    var content = '<div class="form-popup-container fancybox-confirm">' +
      '<div class="form-block-container">' +
        '<div class="subsubheader">' + opts.title + '</div>' +
        opts.message +
        '<div class="form">' +
          '<div class="form-buttons">';

    $.each(opts.buttons, function(key, button) {
      if (button !== false)
        content = content + '<div class="form-button">' +
            '<button class="button-style ' + button.cssClass + '" data-fancybox-close data-value="' + key + '">' + button.label + '</button>' +
          '</div>';
    });

    content = content + '</div>' +
          '</div>' +
        '</div>' +
      '</div>';

    $.fancybox.open({
      type: 'html',
      src: content,
      opts: {
        modal: true,
        baseTpl:
          '<div class="fancybox-container" role="dialog" tabindex="-1">' +
          '<div class="fancybox-bg"></div>' +
          '<div class="fancybox-inner">' +
          '<div class="fancybox-stage"></div>' +
          '</div>' +
          '</div>',
        afterClose: function(widget, currentSlide, e) {
          var button = e ? $(e.target).closest('button') || $(e.currentTarget).closest('button') : null,
              value = button ? $(button).data('value') : 'no';

          opts.callbacks[value](widget, currentSlide);
        }
      }
    });
  };
})();